import type { Profile, User } from "@wwc/types";
import { Timestamp } from "firebase/firestore";
export * from "./address";
export { isRoute, toRoute } from "./routes";
export type { Route } from "./routes";

export const toDate = (ts: Timestamp | Date): Date => {
	if (ts instanceof Timestamp) {
		return ts.toDate();
	}
	return ts;
};

export const fmtDate = (ts: Timestamp, interval?: number): string => {
	const dayInMs = 865e5;
	const hourInMs = dayInMs / 24;
	const minInMs = hourInMs / 60;
	const from = toDate(ts);
	const to = new Date();
	if (interval) {
		from.setDate(from.getDate() + interval);
	}

	const diff = Math.abs(to.valueOf() - from.valueOf());
	let scale: Intl.RelativeTimeFormatUnit = "second";
	let end = 0;
	if (diff > minInMs) {
		scale = "minute";
		end = Math.round(diff / minInMs);
	}
	if (diff > hourInMs) {
		scale = "hour";
		end = Math.round(diff / hourInMs);
	}
	if (diff > dayInMs) {
		scale = "day";
		end = Math.round(diff / dayInMs);
	}
	if (!interval) {
		end *= -1;
	}

	const time = new Intl.RelativeTimeFormat("en-GB", { numeric: "auto" });
	const relativeTime = time.format(end, scale);
	return relativeTime;
};

export const fmtISODate = (str: string | undefined) => {
	if (!str) return;
	if (str.includes("/")) return str;
	return new Date(str).toLocaleDateString("en-GB");
};

export const getPrevBusinessDay = (ts: Timestamp): Date => {
	const date = toDate(ts);
	date.setDate(date.getDate() - 1);
	const prevDay = date.getDay();
	if (prevDay === 0 || prevDay == 6) {
		return getPrevBusinessDay(Timestamp.fromDate(date));
	}
	return date;
};

export const isValidUser = (user: User): boolean => {
	const claims = user.customClaims;
	return claims?.type === "customer" || claims?.type === "admin";
};

export const getUserType = (user?: User | null) => {
	return user?.customClaims?.type || null;
};

export const sleep = async (milliseconds: number) => {
	return new Promise((resolve) => {
		setTimeout(resolve, milliseconds);
	});
};

export const getProfileName = (profile: Profile) => {
	if (!profile.doc) return undefined;
	return [profile.doc.firstName, profile.doc.lastName].join(" ");
};

export const fmtUserName = (
	user: User,
	{ showFullName } = { showFullName: false },
): string => {
	let name;
	if (showFullName) {
		name =
			user.displayName ||
			user.email?.split("@")[0]?.replace(/\./g, " ") ||
			"";
	} else {
		name =
			user.displayName?.split(" ")[0] ||
			user.email?.split(/[.@]/)[0] ||
			"there";
	}
	return name.replace(/\b\w/g, (m) => m.toUpperCase());
};

// prettier-ignore
const countryList =[
	"AF", "AX", "AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW",
	"AU", "AT", "AZ", "BS", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT",
	"BO", "BA", "BW", "BV", "BR", "IO", "VG", "BN", "BG", "BF", "BI", "KH", "CM",
	"CA", "CV", "BQ", "KY", "CF", "TD", "CL", "CN", "CX", "CC", "CO", "KM", "CG",
	"CD", "CK", "CR", "CI", "HR", "CU", "CW", "CY", "CZ", "DK", "DJ", "DM", "DO",
	"EC", "EG", "SV", "GQ", "ER", "EE", "SZ", "ET", "FK", "FO", "FJ", "FI", "FR",
	"GF", "PF", "TF", "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP",
	"GU", "GT", "GG", "GN", "GW", "GY", "HT", "HM", "HN", "HK", "HU", "IS", "IN",
	"ID", "IR", "IQ", "IE", "IM", "IL", "IT", "JM", "JP", "JE", "JO", "KZ", "KE",
	"KI", "KW", "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO",
	"MG", "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "YT", "MX", "FM",
	"MD", "MC", "MN", "ME", "MS", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "NC",
	"NZ", "NI", "NE", "NG", "NU", "NF", "KP", "MK", "MP", "NO", "OM", "PK", "PW",
	"PS", "PA", "PG", "PY", "PE", "PH", "PN", "PL", "PT", "PR", "QA", "RE", "RO",
	"RU", "RW", "WS", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG", "SX", "SK",
	"SI", "SB", "SO", "ZA", "GS", "KR", "SS", "ES", "LK", "BL", "SH", "KN", "LC",
	"MF", "PM", "VC", "SD", "SR", "SJ", "SE", "CH", "SY", "TW", "TJ", "TZ", "TH",
	"TL", "TG", "TK", "TO", "TT", "TN", "TR", "TM", "TC", "TV", "UM", "VI", "UG",
	"UA", "AE", "GB", "US", "UY", "UZ", "VU", "VA", "VE", "VN", "WF", "EH", "YE",
	"ZM", "ZW",
];

export const getCountryList = (topCountries?: string[]) => {
	if (!topCountries) return countryList;
	return topCountries.concat(
		countryList.filter((c) => topCountries.indexOf(c) === -1),
	);
};

export const isDevelopment = () => {
	return import.meta.env.VITE_NODE_ENV === "development";
};
